<template>
    <div class="page">
        <div class="pdf">
            <pdf
                v-for="i in numPages"
                :key="i"
                :src="src"
                :page="i"
                class="page-pdf"
            ></pdf>

            
        </div>
        <div class="pdf-btn">
            <p @click="isShowPopup = true">创建合同</p>
            <img v-if="isLike" src="@/assets/image/like-off.png">
            <img  v-else src="@/assets/image/like-on.png">
        </div>

        <div class="popup" v-if="isShowPopup" @click="isShowPopup = false">
            <div class="popup-content" @click.stop>
                <p class="title">简明告知书</p>
                <div class="content">
                    <p>感谢您使用盾约电子签「物品租赁合同」签署服务(以下简称“本服务”)，请您知悉:</p>
                    <p>1.依法成立并生效的合同具有法律效力，请您基于真实的意愿自主订立、依约行使权利并履行义务。</p>
                    <p>2.本服务提供的物品租赁合同仅为参考文本。双方应当审慎签订合同，在签订合同前，请仔细阅读合同条款，特别是审阅其中具有选择性、补充性、修改性的内容，注意防范潜在风险。其设定与实际情况不一致的，应在合同其他约定中增加或变更约定，并以其他约定为准。</p>
                    <p>3.请您务必基于合法目的使用本服务。如您为出租人，请务必按照合同约定向承租人提供符合合同约定及法律法规规定且您具有出租权的租赁物;如您是承租人，请你以审估坛立和任物口屋古家大六小</p>
                </div>
                <div class="btn">
                    <p @click.stop="creater_HT">我已理解并接受上述内容</p>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
import pdf from '@/components/pdfVue/vuePdfNoSss.vue'
export default {
    data() {
        return {
            src: [],
            pageNum: 1,
            numPages: 0,

            isLike: false,

            isShowPopup: false,
        }
    },
    mounted() {
        let loadingTask = pdf.createLoadingTask('./fzht.pdf');
        this.src = loadingTask
        this.src.promise.then(pdf => {
			this.numPages = pdf.numPages;
		});
    },
    methods:{
        creater_HT(){
            //this.isShowPopup = true
            // eslint-disable-next-line no-undef
            wx.miniProgram.navigateTo({
                url: "/pages/sign/sign"
            })
        }
    },
    components: {
        pdf
    }
}
</script>
<style lang="less" scoped>
.page {
    width: 100%;
    height: 100%;
    overflow: scroll;
}
.pdf {
    background: #F1F3F7;
    padding: 10px 0px 100px 0px;
    .page-pdf {
        margin: 10px 16px;
        border-radius: 7px;
        overflow: hidden;
    }
}
.pdf-btn {
    position: fixed;
    left: 0px;
    bottom: 0px;
    width: 100%;
    background: #F1F3F7;
    height: 60px;
    display: flex;
    padding: 0px 16px;
    justify-content: space-between;
    align-items: center;
    p {
        background: #367BF0;
        color: #fff;
        padding: 10px 0px;
        width: 90%;
        text-align: center;
        border-radius: 100px;
        font-size: 15px;
    } 
    img {
        width: 8%;
    }
}

.popup {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    .popup-content {
        background: #fff;
        position: absolute;
        left: 0px;
        bottom: 0px;
        right: 0px;
        padding: 20px 16px;
        border-radius: 7px 7px 0px 0px;
        color: #000;
        .title {
            text-align: center;
            font-size: 17px;
            font-weight: bold;
            margin-bottom: 20px;
        }
        .content {
            height: 240px;
            max-height: 240px;
            overflow: scroll;
            p {
                color: #2F4670;
                padding-bottom: 20px;
                line-height: 25px;
            }
        }
        .btn {
            height: 60px;
            background: #fff;
            display: flex;
            align-items: center;
            p {
                background: #367BF0;
                color: #fff;
                width: 100%;
                text-align: center;
                padding: 10px 0px;
                border-radius: 100px;
            }
        }
    }
}
</style>