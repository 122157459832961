<template>
    <div class="page">
        <div :class="['pdf', pdfClass ? 'no_padding' : '']">
            <pdf
                v-for="i in numPages"
                :key="i"
                :src="src"
                :page="i"
                class="page-pdf"
            ></pdf>
            <div id="pdfh5"></div>
        </div>

        <div class="top-tip" v-if="[1,2,3,4,5].includes(signType)">合同未生效</div>

        <div class="baohu" v-if="[1,2,3,4,5].includes(signType)">
            <img src="@/assets/image/dp-bg.png"/>
            <div class="ico-text">
                <img src="@/assets/image/dp.png" alt="" srcset="">
                <span>区块链保护中...</span>
            </div>
        </div>
        <div class="pdf-btn" v-if="signType == 1">
           <div @click="stopContractFun">
               <img src="@/assets/image/jy.png" alt="" srcset="">
               <span>中止合同</span>
           </div>
           <!-- <div @click="shareFun"> 
               <img src="@/assets/image/share.png" alt="" srcset="">
               <span>分享</span>
           </div> -->
            <p @click="isShowPopup = true">签署</p>
        </div>
        <div class="pdf-btn" v-else-if="signType == 2">
           <div @click="stopContractFun">
               <img src="@/assets/image/jy.png" alt="" srcset="">
               <span>中止合同</span>
           </div>
           <!-- <div @click="shareFun">
               <img src="@/assets/image/share.png" alt="" srcset="">
               <span>分享</span>
           </div> -->
            <p @click="isShowPopup = true">完成</p>
        </div>
         <div class="pdf-btn other-sign" v-else-if="signType == 3">
            <p class="facing">面对面签署</p>
            <p class="forward">转发乙方签署</p>
        </div>
        <div v-else-if="signType == 4">
            <p class="err-tip-zs">盾约将为您申请调用数字证书进行签署</p>
            <div class="pdf-btn xzyz-zzht" >
                <div v-if="!WaitingSign" @click="refuseContractFun">
                    <img src="@/assets/image/jq.png" alt="" srcset="">
                    <span>拒签</span>
                </div>
                <!-- <div @click="shareFun">
                    <img src="@/assets/image/share.png" alt="" srcset="">
                    <span>分享</span>
                </div> -->
                <p v-if="!WaitingSign" @click="resStampList">选择印章</p>
                <p v-if="!WaitingSign" @click="stopContractFun">中止合同</p>
            </div>
        </div>
        <div class="pdf-btn qrqs" v-else-if="signType == 5">
            <p @click="isShowPopup = true">确认签署</p>
        </div>

        <div class="pdf-btn add-sign" v-else-if="signType == 6">
            <h2 class="title">点击添加签署区</h2>
            <div class="sign-box">
                <p>
                    <span>我方</span>
                </p>
                <p class="btn2">
                    <span>我方</span>
                </p>
            </div>
            <p @click="isShowPopup = true">下一步</p>
        </div>

        <div class="pdf-btn" v-else-if="signType == 7">
            <div>
               <img src="@/assets/image/cz-h.png" alt="" srcset="">
               <span>出征</span>
           </div>
           <!-- <div @click="shareFun">
               <img src="@/assets/image/share.png" alt="" srcset="">
               <span>分享</span>
           </div> -->
            <p>返回</p>
        </div>

        <div class="pdf-btn err" v-else-if="signType == 8">
           <!-- <div @click="shareFun">
               <img src="@/assets/image/share.png" alt="" srcset="">
               <span>分享</span>
           </div> -->
            <p>返回</p>
        </div>

        <div class="pdf-btn create" v-else-if="signType == 9">
            <p @click="isShowPopup = true">创建合同</p>
            <img v-if="tplDatafavoriteId" @click="changeFavorite(0)" src="@/assets/image/like-on.png">
            <img  v-else @click="changeFavorite(1)" src="@/assets/image/like-off.png">
            
        </div>

        <div class="pdf-btn create" v-else-if="signType == 11">
            <p @click="isShowPopup = true">填充合同</p>
            <img v-if="tplDatafavoriteId" @click="changeFavorite(0)" src="@/assets/image/like-on.png">
            <img  v-else @click="changeFavorite(1)" src="@/assets/image/like-off.png">
            
        </div>



        <div class="popup-sign" v-if="isShowPopup" @click="isShowPopup = false">
            <div class="popup-content" @click.stop>
                <p class="title">区块链安全存证技术</p>
                <div class="content">
                    <p>使用盾约电子签发起并签署的合同，将通过区块链平台对用户的所有签署行为进行记录和存储。区块链上存储的数据均无法修改，保证合同签署的安全性。</p>
                </div>
                <div class="btn">
                    <p @click.stop="creater_HT">我知道了</p>
                </div>
            </div>
        </div>


            <!-- qianshu tanchuang  -->
        <div class="popup-sign" v-show="stampPopup" @click="stampPopup = false">
            <div class="popup-content" @click.stop>
                <div class="yin_zhang" v-for="(item,index) in stamp" :key="index" @click="selectStamp(item)">
                    <img :src="addBase64(item.imgBase64)" alt="" >
                </div>
            </div>
        </div>


        <div class="popup-sign stopOrNo" v-show="stopContractFunPopup" @click="stopContractFunPopup = false">
            <div class="popup-content" @click.stop>
                <p class="title">你确定要中止合同吗?</p>
                <div class="btn">
                    <p @click.stop="stopContractFunPopup = false" class="cancel">取消</p>
                    <p @click.stop="resStopContractFun">确定</p>
                </div>
            </div>
        </div>


        <div class="popup-sign stopOrNo" v-show="refuseContractFunPopup" @click="refuseContractFunPopup = false">
            <div class="popup-content" @click.stop>
                <p class="title">你确定要拒绝签署吗?</p>
                <div class="btn">
                    <p @click.stop="refuseContractFunPopup = false" class="cancel">取消</p>
                    <p @click.stop="ResRefuseContractFun">确定</p>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
import pdf from '@/components/pdfVue/vuePdfNoSss.vue'
import Pdfh5 from "pdfh5";
import "pdfh5/css/pdfh5.css";
// import CMapReaderFactory from "vue-pdf/src/CMapReaderFactory.js"
import { previewFile, queryContractTemplateDetail, querySealByOwner, refuseContract, stopContract, queryContractById, favoriteTemplate, delFavoriteTemplate } from '@/api'
export default {
    data() {
        return {
            src: [],
            pageNum: 1,
            numPages: 0,

            isLike: false,

            isShowPopup: false,

            signType: 1, // 签署状态 1 待签署； 2已签署； 3我方已签署； 4预览合同-待对方签署； 5预览合同-对方已签署； 6 预览+签署; 7已生效合同-出征； 8 已过期-终止-拒签  9 预览合同  10 上传文件之后跳转过来  11 status = 1

            tplId: '',
            FileId: '',
            pdfSrc: '',
            token: '',
            accountid: '',
            LUuserId: '', //    机构法人ID

            tplData: {} , // 模版的所有信息
            tplDatafavoriteId: false, //是否收藏

            stamp:[] , // 印章列表
            stampPopup: false,
            stampInfo:{}, // 选择了某一个印章的信息
            userId: '',

            WaitingSign: false, // 是否自己已经签署，等待对方签署

            stopContractFunPopup: false, // 终止合同二次确认弹窗
            refuseContractFunPopup: false, // 拒签二次弹窗确认


            pdfh5: null,

            pdfClass: false,
        }
    },
    mounted() {
        let { type, id, token, accountid, status, userId, LUuserId } = this.$route.query // 必须传入的参数

        //status -1=撤回,0=草稿,1=发起填写中,2=签署中,3=完成签署,4=验签失败,5=完成归档,6=已过期,9=已拒绝
        
        this.tplId = id
        this.token = token
        this.accountid = accountid || ''
        this.userId = userId || ''
        this.LUuserId = LUuserId || ''

        console.log(['3','4','5','6','9'].includes(status))

        if(['3','4','5','6','9'].includes(status)){
            this.signType = 10
            this.pdfClass = true
        }else{
            if(['1'].includes(status)){
                this.signType = 11
                this.pdfClass = false
            }else{
                this.signType = type || 1
                this.pdfClass = false
            }
            
        }


        // type 4合同 ｜ 9模版

        if(type == 10){
            this.resViewTpl({
                id
            })
        }else{
            if(type == 4){
                this.RES_queryContractById()
            }else{
                this.RES_queryContractTemplateDetail()
            }
        }
        
    },
    methods:{
        creater_HT(){
            let stampInfo = this.stampInfo
            // eslint-disable-next-line no-undef
            wx.miniProgram.postMessage({ data: 'foo' })
            if(this.signType == 1){
                // eslint-disable-next-line no-undef
                wx.miniProgram.navigateTo({
                    url: `/pages/certification/certification?type=signTpl&stampId=${stampInfo.id}&orgId=${stampInfo.orgId || stampInfo.userId}`
                })
            }else{
                // eslint-disable-next-line no-undef
                wx.miniProgram.navigateTo({
                    url: `/pages/sign/sign?id=${this.tplId}`
                })
            }
            
        },

        /** 查询合同详情 */
        RES_queryContractById(){
            console.log(this.tplId)
            queryContractById({
                data:{
                    id: this.tplId
                },
                headers:{
                    rdsessionkey: this.token,
                    accountId: this.accountid
                }
            }).then(({ data }) => {
                let res_data = data?.data

                let contractParticipantList = res_data?.contractParticipantList || []

                
                contractParticipantList.map(item=>{
                    if(item.userId == this.userId && item.signId){
                        this.WaitingSign = true
                    }
                })



                this.pdfh5 = new Pdfh5("#pdfh5", {
                    pdfurl: res_data?.signFileUrl
                });
                //监听完成事件
                this.pdfh5.on("complete", function (status, msg, time) {
                    console.log("状态：" + status + "，信息：" + msg + "，耗时：" + time + "毫秒，总页数：" + this.totalNum)
                })


                // window.location.href = res_data?.signFileUrl
                // let loadingTask = pdf.createLoadingTask({
                //     url: res_data?.signFileUrl || res_data?.pdfFormUrl,
                //     CMapReaderFactory,
                //     // cMapPacked: true
                // }); // || './fzht.pdf'


                // this.src = loadingTask
                // this.src.promise.then(pdf => {
                //     this.numPages = pdf.numPages;
                // });
            })
        },

        /** 查看模版详情 */
        RES_queryContractTemplateDetail(){
            queryContractTemplateDetail({
                data:{
                    id: this.tplId * 1
                },
                headers:{
                    rdsessionkey: this.token,
                    accountId: this.accountid || ''
                }
            }).then(({ data })=>{
                if(data.code == 1){
                    //
                    let tplData = data?.data || {}
                    this.tplData = tplData
                    this.tplDatafavoriteId = tplData.favoriteId ? true : false
                    let contractTempFileList = tplData.contractTempFileList || []

                    let fileId = contractTempFileList[0]?.id
                    this.FileId = fileId

                    if(fileId){
                        this.resViewTpl({
                            id: fileId
                        })
                    }else{
                        this.$message.error('没有获取到文件ID');
                    }
                    
                }else{
                    this.$message.error(data.msg);
                }
            })
        },

        /** 请求预览模版 */
        resViewTpl({ id }){
            previewFile({
                data:{
                    id: (id || this.FileId ) * 1,
                },
                headers:{
                    rdsessionkey: this.token,
                    accountId: this.accountid || ''
                }
            }).then(({ data })=>{
                let pdfUrl = data?.data

                if(!pdfUrl){
                    this.$message.error(data.msg);
                }


                let loadingTask = pdf.createLoadingTask(pdfUrl); // || './fzht.pdf'
                this.src = loadingTask
                this.src.promise.then(pdf => {
                    this.numPages = pdf.numPages;
                });
            })
        },

        /** 点击选择印章 */
        resStampList(){
            querySealByOwner({
                headers:{
                    rdsessionkey: this.token,
                    accountId: this.accountid
                }
            }).then(({ data })=>{
                this.stamp = data.data || []
                this.stampPopup = true

            })
        },

        refuseContractFun(){
            this.refuseContractFunPopup = true
        },

        /** 点击拒绝签署 */
        ResRefuseContractFun(){
            refuseContract({
                data:{
                    id: this.tplId,
                },
                headers:{
                    rdsessionkey: this.token,
                    accountId: this.accountid
                }
            }).then(({ data })=>{
                if(data.code == 1){
                    this.$message.success('拒签成功');
                    setTimeout(()=>{
                        // eslint-disable-next-line no-undef
                        wx.miniProgram.reLaunch({
                            url: `/pages/contract/contract?headerSelect=2`
                        })
                    },2000)
                }else{
                    data.msg ? this.$message.error(data.msg) : '';
                }
            })
        },

        // 点击分享
        shareFun(){

        },

        stopContractFun(){
            this.stopContractFunPopup = true
        },

        /** 点击中止合同 */
        resStopContractFun(){
            stopContract({
                data:{
                    id: this.tplId,
                },
                headers:{
                    rdsessionkey: this.token,
                    accountId: this.accountid
                }
            }).then(({ data })=>{
                if(data.code == 1){
                    this.$message.success('合同已经终止成功');
                    setTimeout(()=>{
                        // eslint-disable-next-line no-undef
                        wx.miniProgram.reLaunch({
                            url: `/pages/contract/contract?headerSelect=2`
                        })
                    },2000)
                }else{
                    if(data.msg){
                        this.$message.error(data.msg);
                    }
                    
                }
            })
        },

        /** 点击了某一个印章 */
        selectStamp(item){
            if(item.userId){ // 个人印章不需要拦截
                this.stampPopup = false
                this.stampInfo = item
                this.signType = 1
            }else{
                if(this.LUuserId == this.userId){
                    this.stampPopup = false
                    this.stampInfo = item
                    this.signType = 1
                }else{
                    this.$message.error('无此印章权限');
                }
            }
            
            
            // eslint-disable-next-line no-undef
            // wx.miniProgram.navigateTo({
            //     url: `/pages/certification/certification?type=signTpl&stampId=${item.id}&orgId=${item.orgId}`
            // })
        },

        addBase64(data){
            if(data.indexOf('data:image/png;base64,')>= 0){
                return data
            }else{
                return `data:image/png;base64,${data}`
            }
        },

        /** 点击收藏或者取消收藏 */
        changeFavorite(status){
            if(status == 1){ // 收藏
                favoriteTemplate({
                    data:{
                        id: this.tplId
                    },
                    headers:{
                        rdsessionkey: this.token,
                        accountId: this.accountid
                    }
                }).then(({ data })=>{
                    if(data.code == 1){
                        this.tplDatafavoriteId = true
                    }else{
                        this.$message.error(data.msg);
                    }
                })

            }else{ // 取消收藏
                delFavoriteTemplate({
                    data:{
                        id: this.tplId
                    },
                    headers:{
                        rdsessionkey: this.token,
                        accountId: this.accountid
                    }
                }).then(({ data })=>{
                    if(data.code == 1){
                        this.tplDatafavoriteId = false
                    }else{
                        this.$message.error(data.msg);
                    }
                })
            }
        }
    },
    components: {
        pdf
    }
}
</script>
<style lang="less" scoped>
.top-tip {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    background: rgba(54, 123, 240, 0.5);
    color: #fff;
    text-align: center;
    padding: 16px 0px;
    z-index: 999;
}
.page {
    width: 100%;
    height: 100%;
    overflow: scroll;
}
.pdf {
    background: #F1F3F7;
    padding: 10px 0px 60px 0px;
    height: 100%;
    .page-pdf {
        margin: 10px 16px;
        border-radius: 7px;
        overflow: hidden;
    }
}
.no_padding {
    padding: 0px 0px 0px 0px;
}

.baohu {
    position: fixed;
    left: 0px;
    bottom: 60px;
    padding: 0px 16px;
    width: 100%;
    height: 36px;
    &>img {
        position: absolute;
        top: 0px;
        left: 32px;
        right: 0px;
        width: calc(100% - 64px);
    }
    .ico-text {
        position: relative;
        z-index: 999;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 36px;
        img {
            width: 18px;
            margin-right: 5px;
        }
        span {
            color: #fff;
        }
    }
}
.pdf-btn {
    position: fixed;
    left: 0px;
    bottom: 0px;
    width: 100%;
    background: #F1F3F7;
    height: 60px;
    display: flex;
    padding: 0px 16px;
    justify-content: space-between;
    align-items: center;
    &>p {
        background: #367BF0;
        color: #fff;
        padding: 10px 0px;
        width: 69%;
        text-align: center;
        border-radius: 100px;
        font-size: 15px;
    }
    div {
        width: 15%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
            width: 35%;
        }
        span {
            font-size: 10px;
            padding-top: 3px;
            color: #5F666F;
        }
    }
    
}

.xzyz-zzht {
    div {
        min-width: 15%;
    }
    p:last-child {
        margin-left: 10px;
    }
}
.other-sign {
    .facing {
        border-radius: 100px 0px 0px 100px;
        background: linear-gradient(-90deg, #0790FC 0%, #6ABCFC 100%);
    }
    .forward {
        border-radius: 0px 100px 100px 0px;
    }
}

.qrqs {
    p {
        width: 100%;
    }
}

.add-sign {
    flex-direction: column;
    height: 180px;
    padding-bottom: 20px;
    .title {
        padding: 12px 0px 5px 0px;
        font-size: 15px;
    }
    .sign-box {
        display: flex;
        width: 100%;
        flex-direction: row;
        padding-bottom: 20px;
        p {
            border-radius: 10px;
            border: 1px solid #F9E2AC;
            margin: 0px 10px;
            color: #fff;
            font-size: 15px;
            span {
                margin: 1px;
                background: #F9E2AC;
                height: 100%;
                color: #fff;
                display: block;
                padding: 14px 35px;
                border-radius: 10px;
            }
        }
        .btn2 {
            border: 1px solid #8DC5AE;
            span {
                background: #8DC5AE;
            }
        }
    }
}
.err-tip-zs {
    position: absolute;
    bottom: 60px;
    width: 100%;
    text-align: center;
    background: rgba(121, 161, 147, 0.3);
    left: 0px;
    color: #ff3300;
    line-height: 26px;
    font-size: 12px;
    z-index: 999999;
}
.err {
    &>p {
        width: 90%;
    }
}
.create {
    p {
        width: 90%;
    }
    img {
        width: 8%;
    }
}

.popup-sign {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    transition: all 0s ease-in;
    .popup-content {
        background: #fff;
        position: absolute;
        left: 0px;
        bottom: 0px;
        right: 0px;
        padding: 20px 16px;
        border-radius: 7px 7px 0px 0px;
        color: #000;
        .title {
            text-align: center;
            font-size: 17px;
            font-weight: bold;
            margin-bottom: 20px;
        }
        .content {
            height: 240px;
            max-height: 240px;
            overflow: scroll;
            p {
                color: #2F4670;
                padding-bottom: 20px;
                line-height: 25px;
            }
        }
        .btn {
            height: 60px;
            background: #fff;
            display: flex;
            align-items: center;
            p {
                background: #367BF0;
                color: #fff;
                width: 100%;
                text-align: center;
                padding: 10px 0px;
                border-radius: 100px;
            }
        }
    }
}

.stopOrNo {
    .btn {
        display: flex;
        justify-content: space-between;
        p {
            width: 45%;
            margin: 0px 5px;
        }
        p.cancel {
            background: #F1F3F7;
            color: #2F4670;
        }

    }
}

.yin_zhang {
    text-align: center;
    padding: 20px 0px;
    border-bottom: 1px solid #ececec;
    img {
        width: 50%;
        
    }
}


</style>