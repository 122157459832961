import { post } from '@/http/http'
// 模版文件预览
export const previewFile = (option) => {
    return post('/esignServer/contractTemplateController/previewFile', option)
}

// 查看模版详情
export const queryContractTemplateDetail = (option) => {
    return post('/esignServer/contractTemplateController/queryContractTemplateDetail', option)
}

// 查询合同详情
export const queryContractById = (option) => {
    return post('/esignServer/contractController/queryContractById', option)
}


// 查询印章
export const querySealByOwner = (option) => {
    return post('/esignServer/sealController/querySealByOwner', option)
}


//用户拒绝签署
export const refuseContract = (option) => {
    return post('/esignServer/signController/refuseContract', option)
}

//用户中止合同
export const stopContract = (option) => {
    return post('/esignServer/signController/stopContract', option)
}

// 收藏模板
export const favoriteTemplate = (option) => {
    return post('/esignServer/contractTemplateController/favoriteTemplate', option)
}

// 删除收藏记录
export const delFavoriteTemplate = (option) => {
    return post('/esignServer/contractTemplateController/delFavoriteTemplate', option)
}




 




 



 
